<div class="title flex items-baseline"> <div> Change Status - &nbsp;</div>
    <form [formGroup]="stateForm">   
        <mat-form-field style="width: 82px;">
            <mat-select name="state" formControlName="state">
            <mat-option (click)="updateState(state.value)" *ngFor="let state of states | keyvalue" [value]="state.key">
                {{ state.key }}
            </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div style=" max-height: 80%; overflow: auto; margin: 0 !important;" >
    <form
        class="mt-4 mb-4 justify-center flex items-center"
        [formGroup]="statusForm">   
    <div>
        <div class="flex-col groups" style="gap: 5px;">
            <mat-radio-group formControlName="status" class="flex flex-col">
                <mat-radio-button value="Good">{{data.legend.good}}</mat-radio-button>
                <mat-radio-button value="Unknown">{{data.legend.unknown}} </mat-radio-button>
                <mat-radio-button value="Bad"> {{data.legend.bad}}</mat-radio-button>
                <mat-radio-button *ngIf="data.type == 'nexus_status_report' " value="NA">{{data.legend.na}}</mat-radio-button>
                <mat-radio-button *ngIf="data.type == 'SOS_status' " value="N/A">{{data.legend.na}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
   </form>
  </div>
   <div style=" display: flex; justify-content: space-between; margin: 0 10px;">
    <button
        class="fuse-mat-button "
        mat-flat-button
        [color]="'accent'"  
        [disabled]=""
        (click)="onSave()"
        type="button">
        <span>
            Save
        </span>
    </button> 
    &nbsp;&nbsp;&nbsp;
    <button
        class="fuse-mat-button "
        mat-flat-button
        [color]="'accent'"
        (click)="onCancel()"
        type="button">
        <span>
            Cancel
        </span>
    </button>
   </div>