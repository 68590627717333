import jwt_decode from "jwt-decode";

export class JWTDecoder {
  constructor() {}


  static parseToken(token): any {
    return token ? jwt_decode(token) : '';
  }

  static checkPermissionsAccess(permission): any {
    let currenToken: any = localStorage.getItem('token');
    let permissions: any = JSON.stringify(JWTDecoder.parseToken(currenToken)?.permissions);
    return permissions.includes('"' + permission) ? true : false;
  }
  
  static isPermissionsAccess(): any {
    let currenToken: any = localStorage.getItem('token');
    let permissions: any = JSON.stringify(JWTDecoder.parseToken(currenToken)?.permissions);
    return (permissions != '[]' && permissions?.length > 0) ? true : false;
  }

  static checkMenuComponents(permission): any {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let permissions: any = user?.components;
    let result = permissions.map(perm => perm.code);
    return result.includes(permission) ? true : false;
  }

  static getMenuComponents(): any {
    // let permissions: any = [
    //   {id: 1, name: "Secretary of State", code: 'sos'},  //sos
    //   {id: 2, name: "Tax Returns", code: 'tax_returns'},
    //   {id: 3, name: "Sales Tax", code: 'tax_sales'}, 
    //   {id: 4, name: "QuickBooks Reports", code: 'qb'},
    //   {id: 5, name: "Entities Management", code: 'entities'},
    //   {id: 6, name: "Configuration", code: 'system'}
    // ]
    let user: any = JSON.parse(localStorage.getItem('user'));
    let permissions: any = user?.components;
    let result = JSON.stringify(permissions.map(perm => perm.code));
    return result;
  }

}
