import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatch, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { ConstsHelper } from 'app/core/helpers/consts-helper';
import { JWTDecoder } from 'app/jwt_decoder/jwt_decoder';

@Injectable({
    providedIn: 'root'
})
export class ComponentsGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        
        const requiredPermission = next.data['permission'];
     // Проверка разрешения на доступ на основе переданных данных
     if (!JWTDecoder.checkMenuComponents(requiredPermission)) {
       // Если доступ запрещен, перенаправляем на дефолтный маршрут
       this._router.navigate(['/dashboard']);
       return false;
     }
        // Проверка разрешения на доступ на основе переданных данных
        return JWTDecoder.checkMenuComponents(requiredPermission); // Возвращает true или false
      }
}
