import { Injectable } from '@angular/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Utf8 from 'crypto-js/enc-utf8';
import { FuseMockApiService } from '@fuse/lib/mock-api';

@Injectable({
    providedIn: 'root'
})
export class AuthMockApi {
    private readonly _secret: any;

    constructor(private _fuseMockApiService: FuseMockApiService) {
        this._secret = 'YOUR_VERY_CONFIDENTIAL_SECRET_FOR_SIGNING_JWT_TOKENS!!!';
        this.registerHandlers();
    }

    registerHandlers(): void
    {
        this._fuseMockApiService
            .onPost('/auth/resetPassword', 1000)
            .reply(() =>
                [
                    200,
                    true
                ]
            );
    
        this._fuseMockApiService
            .onPost('/auth/login', 1500)
            .reply(({request}) => {
                if ( request.body.email === 'example@mail.ru' && request.body.password === '123456789' )
                {
                    return [
                        200,
                        {
                            access_token: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjg2MTI5NjQyLCJleHAiOjE2ODYyMTYwNDIsInVzZXJJZCI6MSwidGVuYW50SWQiOjEsInVzZXJOYW1lIjoiYWRtaW4gYWRtaW4iLCJ1c2VyRW1haWwiOiJpbmZvQHZpbm5pa2NwYS5jb20iLCJwZXJtaXNzaW9ucyI6WyJzb3Nfc3RhdHVzX3dyaXRlIiwic29zX2NoZWNrX3dyaXRlIiwic29zX3JlcG9ydF93cml0ZSIsInRheF9yZXR1cm5zX2NvcnBvcmF0ZV93cml0ZSIsInRheF9zYWxlc19yZXBvcnRfd3JpdGUiLCJ0YXhfc2FsZXNfc3RhdHVzX3dyaXRlIiwicWJfcmVwb3J0c19zdGF0dXNfd3JpdGUiLCJxYl9yZXBvcnRzX2V4ZWN1dGVfd3JpdGUiLCJzeXN0ZW1fY29uZmlnX2NjaF93cml0ZSIsImNvbXBhbnlfbWFuYWdlbWVudF93cml0ZSIsInRheF9yZXR1cm5zX2luZGl2aWR1YWxfd3JpdGUiLCJ0YXhfc2FsZXNfZmluZF93cml0ZSIsInN5c3RlbV91c2Vyc193cml0ZSIsInRheF9yZXR1cm5zX3N0YXR1c193cml0ZSIsInN5c3RlbV9jb25maWdfYXdhbGFyYV93cml0ZSJdfQ.00NBzdOwXfxDoke5HsxKvvNbsvjEoIMzneYDe5m7ldMmb3VcZnXfrpCqAYXY7neGR6t-PK7QwjAhqcaByex0qA'
                            //this._generateJWTToken()
                        }
                    ];
                }

                return [
                    404,
                    false
                ];
            });

        this._fuseMockApiService
            .onGet('/auth/logout', 1000)
            .reply(() =>
                [
                    200,
                    true
                ]
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Return base64 encoded version of the given string
     *
     * @param source
     * @private
     */
    private _base64url(source: any): string
    {
        // Encode in classical base64
        let encodedSource = Base64.stringify(source);

        // Remove padding equal characters
        encodedSource = encodedSource.replace(/=+$/, '');

        // Replace characters according to base64url specifications
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');

        // Return the base64 encoded string
        return encodedSource;
    }

    /**
     * Generates a JWT token using CryptoJS library.
     *
     * This generator is for mocking purposes only and it is NOT
     * safe to use it in production frontend applications!
     *
     * @private
     */
    private _generateJWTToken(): string
    {
        // Define token header
        const header = {
            alg: 'HS256',
            typ: 'JWT'
        };

        // Calculate the issued at and expiration dates
        const date = new Date();
        const iat = Math.floor(date.getTime() / 1000);
        const exp = Math.floor((date.setDate(date.getDate() + 7)) / 1000);

        // Define token payload
        const payload = {
            iat: iat,
            iss: 'Fuse',
            exp: exp
        };

        // Stringify and encode the header
        const stringifiedHeader = Utf8.parse(JSON.stringify(header));
        const encodedHeader = this._base64url(stringifiedHeader);

        // Stringify and encode the payload
        const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
        const encodedPayload = this._base64url(stringifiedPayload);

        // Sign the encoded header and mock-api
        let signature: any = encodedHeader + '.' + encodedPayload;
        signature = HmacSHA256(signature, this._secret);
        signature = this._base64url(signature);

        // Build and return the token
        return encodedHeader + '.' + encodedPayload + '.' + signature;
    }
}
