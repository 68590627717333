export const breadcrumbData: any[] = [
    {
        id: '/list/individuals',
        data: [
            {
                label: 'Lists',
                url: ''
            },
            {
                label: 'Individuals',
                url: ''
            }
        ]
    },
    {
        id: '/list/corporates',
        data: [
            {
                label: 'Lists',
                url: ''
            },
            {
                label: 'Corporates',
                url: ''
            }
        ]
    },
    {
        id: '/tax-returns/individuals',
        data: [
            {
                label: 'Returns',
                url: ''
            }
        ]
    },
    {
        id: '/tax-returns/corporates',
        data: [
            {
                label: 'Returns',
                url: 'tax-returns/individuals'
            },
            {
                label: 'Corporates',
                url: ''
            }
        ]
    },
    {
        id: '/report/generation',
        data: [
            {
                label: 'Reports',
                url: ''
            }
        ]
    },
    {
        id: '/report/management',
        data: [
            {
                label: 'Reports',
                url: 'report/generation'
            },
            {
                label: 'Management',
                url: ''
            }
        ]
    }
]