import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogResult } from 'app/core/models/helper.model';
import { AlertService } from 'app/core/services/alert.service';
import { UsersService } from 'app/core/services/configuration.service';
import { SharedModule } from 'app/core/shared.module';
import { errorText } from 'app/i18/errorsText';

@Component({
  selector: 'app-states-dialog',
  templateUrl: './states-dialog.component.html',
  styleUrls: ['./states-dialog.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class StatesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<StatesDialogComponent, any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _service: UsersService,
    private _alertService: AlertService
  ) {}

  public isShowForm: boolean = true;
  public isUpdateUser: boolean = false;
  public sendForm: boolean = false;
  statusForm: FormGroup;
  stateForm: FormGroup;
  public states = {
  AK: "Unknown",
  AL: "Unknown",
  AR: "Unknown",
  AZ: "Unknown",
  CA: "Unknown",
  CO: "Unknown",
  CT: "Unknown",
  DE: "Unknown",
  DC: "Unknown",
  FL: "Unknown",
  GA: "Unknown",
  HI: "Unknown",
  IA: "Unknown",
  ID: "Unknown",
  IL: "Unknown",
  IN: "Unknown",
  KS: "Unknown",
  KY: "Unknown",
  LA: "Unknown",
  MA: "Unknown",
  MD: "Unknown",
  ME: "Unknown",
  MI: "Unknown",
  MN: "Unknown",
  MO: "Unknown",
  MS: "Unknown",
  MT: "Unknown",
  NC: "Unknown",
  ND: "Unknown",
  NE: "Unknown",
  NH: "Unknown",
  NJ: "Unknown",
  NM: "Unknown",
  NV: "Unknown",
  NY: "Unknown",
  OH: "Unknown",
  OK: "Unknown",
  OR: "Unknown",
  PA: "Unknown",
  RI: "Unknown",
  SC: "Unknown",
  SD: "Unknown",
  TN: "Unknown",
  TX: "Unknown",
  UT: "Unknown",
  VA: "Unknown",
  VT: "Unknown",
  WA: "Unknown",
  WI: "Unknown",
  WV: "Unknown",
  WY: "Unknown"
}

  ngOnInit() {
    this.statusForm = this._formBuilder.group({
      status: [this.data.value , Validators.required]
    })
    Object.keys(this.states).forEach((state) => {
      if (this.data.allStates.hasOwnProperty(state)) {
        this.states[state] = this.data.allStates[state];
      } 
    });
    this.stateForm = this._formBuilder.group({
      state: this.data.state
    })
  }

onCancel() {
  let dialogResult = <DialogResult>{
    isSuccess: false
  }
  this.dialogRef.close(dialogResult);
}  

openSnackBar(message: string, action: string, type: number) {
  this._snackBar.open(message, action, {
    panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
    duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
  });
}
 public updateState(value){
  this.statusForm.get('status').setValue(value);
 }

onSave() {
  this.sendForm = true;
  
  if (this.statusForm.invalid) {
    this.statusForm.markAllAsTouched();
    return false
  }

  let data = {
    id: this.data.id,
    state: this.stateForm.value.state,
    status: this.statusForm.value.status,
  }

  if(this.data.type == 'SOS_status'){
    this._service.updateStateSOSStatus(data).subscribe((res: any) => {
      let dialogResult = <DialogResult>{
        isSuccess: true,
        message: 'State successfully updated'
      }
      this.dialogRef.close(dialogResult);
      },
      (err)=>{
        this.openSnackBar(errorText.errorMessage(err.code),'Cancel', 2);
    });
  } else if(this.data.type == 'nexus_status_report'){
    this._service.updateStateNexus(data).subscribe((res: any) => {
      let dialogResult = <DialogResult>{
        isSuccess: true,
        message: 'State successfully updated'
      }
      this.dialogRef.close(dialogResult);
      },
      (err)=>{
        this.openSnackBar(errorText.errorMessage(err.code),'Cancel', 2);
    });
  }
}


}