import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { SharedModule } from 'app/core/shared.module';
import { ClassicLayoutComponent } from 'app/layout/layouts/classic/classic.component';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { FooterComponent } from 'app/components/footer/footer.component';

@NgModule({
    declarations: [
        ClassicLayoutComponent,
        FooterComponent
    ],
    exports: [
        ClassicLayoutComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        SharedModule,
        NgDynamicBreadcrumbModule
    ]
})
export class ClassicLayoutModule
{
}
