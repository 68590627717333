export class ConstsHelper {  
    static readonly urlAuth = `/auth`;
    static readonly urlReport = `/report`;
    static readonly urlReturn = `/return`;
    static readonly urlConfiguration = `/configuration`;
    static readonly urlSales = `/sales`;

    static readonly USER_TOKEN = 'token';

    static readonly CCH_CORPORATE = '152352';
    static readonly CCH_INDIVIDUAL = '163222';
}