import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { AlertService } from '../services/alert.service';
import { ConstsHelper } from '../helpers/consts-helper';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor (
        private _authService: AuthService, 
        private _alertService: AlertService,
        private dialogRef: MatDialog,
        private _snackBar: MatSnackBar,
        private _router: Router
    ) {}

    openSnackBar(message: string, action: string, type: number) {
        this._snackBar.open(message, action, {
          panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
          duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req.clone();
        let token = (this._authService.jwtToken !== null && this._authService.jwtToken !== undefined) ? 
        this._authService.jwtToken : JSON.parse(localStorage.getItem('token'));
        if (token)
        {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });
        }

        return next.handle(newReq).pipe(
            catchError((error) => {
               // console.log(error);
                
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                    // if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        this.dialogRef.closeAll();
                        if(!error.url.includes('auth/logout') || error.status != 401){
                          this.openSnackBar('You have been logged out. Please log in again','Cancel', 2);
                        }
                        this._router.navigate(['/']);
                        this._authService.removeJwt();
                        //this._router.navigate(['/authsign-in']);
                       // this._authService.signOut('error');
                        // location.reload();
                    }
                    if (error.status === 403) {
                        this.openSnackBar('Access Denied','Cancel', 2);
                    }
                    if (error.status === 500) {
                        this._alertService.message('Server error', 'error');
                    }
                    else {
                        this._alertService.message(error.message, 'error');
                     //   console.log(error.message);
                    }
                }
                
                return throwError(() => error.error);
            })
        );
    }
}
