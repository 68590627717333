import { Injectable } from '@angular/core';
import { CanMatch, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanMatch {
    constructor(public _authService: AuthService) {}

    canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let token = (this._authService.jwtToken !== null && this._authService.jwtToken !== undefined) ? this._authService.jwtToken : JSON.parse(localStorage.getItem('token'));
        return of(!token);
    }
}
