export interface IReportType {
    id: number;
    name: string;
}

export class ReportType implements IReportType {
    id: number;
    name: string = '';
}

export interface IReportPeriod {
    id: number;
    name: string;
}

export class ReportPeriod implements IReportPeriod {
    id: number;
    name: string = '';
}

export interface IClient {
    id: number;
    name: string;
}

export class Client implements IClient {
    id: number;
    name: string = '';
}

export interface IReportsClient {
    cchIdCurr: number;
    name: string;
}

export class ReportsClient implements IReportsClient {
    cchIdCurr: number;
    name: string = '';
}

export class IImmediateReport {
    clientIds: number[];
    reportTypeIds: number[];
    reportPeriodId: number;
}

export class ImmediateReport implements IImmediateReport {
    clientIds: number[] = [];
    reportTypeIds: number[] = [];
    reportPeriodId: number;
}

export class IScheduledReport {
    clientIds: number[];
    reportTypeIds: number[];
    reportPeriodId: number;
    name: string;
    startDate: string;
    repeatAfterNumber: number;
    repeatAfterType: number;
    repetitionDate?: string;
    repetitionDay?: number;
    endType: number;
    endDate?: string;
    repetitionNumber?: number;
}

export class ScheduledReport implements IScheduledReport {
    clientIds: number[] = [];
    reportTypeIds: number[] = [];
    reportPeriodId: number;
    name: string = '';
    startDate: string;
    repeatAfterNumber: number;
    repeatAfterType: number;
    repetitionDate?: string;
    repetitionDay?: number;
    endType: number;
    endDate?: string;
    repetitionNumber?: number;
}

export interface IScheduledReportBaseInfo {
    id: number;
    name: string;
    creationDate: Date;
}

export class ScheduledReportBaseInfo implements IScheduledReportBaseInfo {
    id: number;
    name: string = '';
    creationDate: Date;
}

export class DeleteReportsDto {
    reportIds: number[];
  
    constructor(id: number[]) {
      this.reportIds = id;
    }
}