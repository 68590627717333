import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { navigationData } from 'app/core/helpers/navigation-data';
import { Subject } from 'rxjs';
import { ToolsService } from './tools.service';

@Injectable({
    providedIn: 'root',
})

export class NavigationMenuService {
    

    private menuUpdateSource = new Subject<void>();
    menuUpdate$ = this.menuUpdateSource.asObservable();    
    
    constructor(private _http: HttpClient, 
      private _controlService: ToolsService,
      private _snackBar: MatSnackBar) {}

    openSnackBar(message: string, action: string, type: number) {
        this._snackBar.open(message, action, {
            panelClass:
                type == 1
                    ? 'app-notification-success'
                    : 'app-notification-error',
            duration: 5 * 1000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }

    triggerMenuUpdate() {
      this.menuUpdateSource.next();
    }

    async getNavMenuConfig(){
        // let currentNavMenu:any[] = navigationData;
        let currentTenantId = JSON.parse(localStorage.getItem('user'))?.isRoot;
        let currentNavMenu: any[] = JSON.parse(JSON.stringify(navigationData));
        if(currentTenantId){
            await new Promise((resolve, reject) => {
            this._controlService.gettoolsList().subscribe((result: any) => {
                let tools = result;
                tools.forEach(newElem => {
                    if (newElem.componentCode === 'qb') {
                        let qbPermission = 'qb';
                        let qbParent = currentNavMenu.find(item => item.permissions === qbPermission);
                
                        if (qbParent) {
                            qbParent.children.push({
                                id: 'tool',
                                title: newElem.name,
                                isMainComponent: false,
                                type: 'basic',
                                permissions: '',
                                description: newElem.description,
                                link: newElem.url
                            });
                        }
                    } else if (newElem.componentCode === 'tax_sales') {
                        let taxSalesPermission = 'tax_sales';
                        let taxSalesParent = currentNavMenu.find(item => item.permissions === taxSalesPermission);
                
                        if (taxSalesParent) {
                            taxSalesParent.children.push({
                                id: 'tool',
                                title: newElem.name,
                                isMainComponent: false,
                                type: 'basic',
                                permissions: '',
                                description: newElem.description,
                                link: newElem.url
                            });
                        }
                    } else if (newElem.componentCode === 'sos') {
                        let sosPermission = 'sos';
                        let sosParent = currentNavMenu.find(item => item.permissions === sosPermission);
                
                        if (sosParent) {
                            sosParent.children.push({
                                id: 'tool',
                                title: newElem.name,
                                isMainComponent: false,
                                type: 'basic',
                                permissions: '',
                                description: newElem.description,
                                link: newElem.url
                            });
                        }
                    }
                });
                resolve(currentNavMenu);
            },
            (err) => {
                reject(err);
            });
            });
        }
        
        return  currentNavMenu;
    }

  
}
