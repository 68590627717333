import { Injectable } from '@angular/core';
import { CanMatch, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { ConstsHelper } from 'app/core/helpers/consts-helper';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanMatch {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) {}

    canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!JSON.parse(localStorage.getItem(ConstsHelper.USER_TOKEN))) {
            const urlTree = this._router.parseUrl('auth');
            return of(urlTree);
        }
        return of(true);
    }
}
