<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center justify-center h-20 p-6">
            <img
                style="width: 12rem;"
                src="assets/images/logo_name_white.png"
                alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
<!-- 
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button 
                mat-icon-button 
                matTooltip="Lists"
                routerLink="list"
            >
                <mat-icon [svgIcon]="'mat_outline:wysiwyg'"></mat-icon>
            </button>
            <button 
                mat-icon-button 
                matTooltip="Returns"
                routerLink="return"
            >
                <mat-icon [svgIcon]="'mat_outline:request_page'"></mat-icon>
            </button>
            <button 
                mat-icon-button
                matTooltip="Reports"
                routerLink="report"
            >
                <mat-icon [svgIcon]="'heroicons_outline:document-report'"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Fields Mapping">
                <mat-icon [svgIcon]="'heroicons_outline:view-grid'"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="System Configuration">
                <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            </button>
        </div> -->

        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <p>{{userName}}</p>
            <button
                mat-icon-button
                matTooltip="Logout"
                (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            </button>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <!-- <app-ng-dynamic-breadcrumb
            [fontSize]="'16px'"
            [bgColor]="'aliceblue'"
            [fontColor]="'darkblue'"></app-ng-dynamic-breadcrumb> -->
        <router-outlet></router-outlet>
        <app-footer *ngIf="showFooter"></app-footer>
    </div>
</div>
