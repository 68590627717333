import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ConstsHelper } from '../helpers/consts-helper';
import { HttpClient } from '@angular/common/http';
import { Response, ListResponse } from '../models/response.model';
import { DeleteReturnsDto, ReturnBaseInfo } from '../models/return.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorText } from 'app/i18/errorsText';
import { catchError, map, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  private readonly _baseUrl = environment.apiUrl + ConstsHelper.urlReturn;
  private readonly _url = environment.apiUrl;

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, type: number) {
    this._snackBar.open(message, action, {
      panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
      duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
    });
  }

getTools(params) { 
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/tool?componentCode=${params.component}&page=${
        params.pageNum}&size=${params.size}${
        params?.sortName ? `&${params.sortName}=${params.sortColumnId}` : ''}`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  
} 

deleteTools(data: string[]) {
  return this._http.delete<Response>(`${this._url.replace('/rbac', '')}/config/tool?toolIds=${data}`).pipe(
    map(response => {
      return response;
    }),
    catchError((error: any) => {
     // this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
      return throwError(error);
    })
  );
}

  gettoolsList() { 
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/tool/all`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );;
  }

  createTool(data) { 
      return this._http.post<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/tool`, data).pipe(
        map(response => {
          return response;
        }),
        catchError((error: any) => {
          this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
          return throwError(error);
        })
      );
  } 

  updateTool(data, toolID) { 
      return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/tool/${toolID}`, data).pipe(
        map(response => {
          return response;
        }),
        catchError((error: any) => {
          this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
          return throwError(error);
        })
      );
  } 

}
