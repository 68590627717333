<div class="prose text-gray-700 max-w-none p-[2rem]">
  <h1><strong>TERMS OF USE</strong></h1>
  <p><strong>Last updated November 5, 2023</strong></p>
  <p><strong>AGREEMENT TO TERMS</strong></p>
  <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of a
    company or other legal entity (“you” or “your”) and Vinnik CPA, LLC. (“we,” “us” or “our”), concerning your access
    to and use of the VINNIK CPA platform and website as well as any other media form, media channel, mobile website or
    mobile application related, linked, or otherwise connected thereto (collectively, the “Platform”). You agree that by
    accessing the Platform, you have read, understood, and agree to be bound by all these Terms of Use. If you are
    entering into this agreement on behalf of a company or other legal entity, you represent that you have the authority
    to bind such entity and its employees and affiliates to this agreement. </p>
  <p>Supplemental terms and conditions or documents that may be posted on the Platform from time to time are hereby
    expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
    modifications to these Terms of Use at any time and for any reason, and when changes or modifications are made, we
    will update the “Last updated” date of these Terms of Use. It is your responsibility to periodically review these
    Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and
    to have accepted, the changes in any revised Terms of Use by your continued use of the Platform after the date of
    such revised Terms of Use.</p>
  <p>The information provided on the Platform is not intended for distribution to or use by any person or entity in any
    jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject
    us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to
    access the Platform from other locations do so on their own initiative and are solely responsible for compliance
    with local laws, if and to the extent local laws are applicable. </p>
  <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
  <p>Unless otherwise indicated, the Platform is our proprietary property and all source code, databases, functionality,
    software, website designs, audio, video, text, photographs, and graphics on the Platform (collectively, the
    “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by
    us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property
    rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions. The
    Content and the Marks are provided on the Platform “AS IS” for your information and personal use only. Except as
    expressly provided in these Terms of Use, no part of the Platform and no Content or Marks may be copied, reproduced,
    aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold,
    licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written
    permission.</p>
  <p>Subject to the terms of this agreement, we grant you a limited revocable, non-exclusive, non-sublicensable,
    non-transferrable license to copy and use the Content solely in connection with your permitted use of the Platform
    during the Term. We reserve all rights not expressly granted to you in and to the Platform, the Content and the
    Marks.</p>
  <p><strong>USER REPRESENTATIONS</strong></p>
  <p>By using the Platform, you represent and warrant that: (1) you have the legal capacity and you agree to comply with
    these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3) you will not access the
    Platform through automated or non-human means, whether through a bot, script or otherwise without prior written
    consent from us; (4) you will not use the Platform for any illegal or unauthorized purpose; and (5) your use of the
    Platform will not violate any applicable law or regulation.</p>
  <p>You agree to keep your password confidential and will be responsible for all use of your account and password.</p>
  <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend
    or terminate your account and refuse all current or future use of the Platform (or any portion thereof). </p>
  <p><strong>FEES AND PAYMENT</strong></p>
  <p>You are required to pay a fee to access our Platform. Such fees shall be paid in advance, either monthly, annually
    or in accordance with any different billing frequency agreed to when signing up for service (the “Subscription
    Period”). You agree to provide current, complete, and accurate purchase and account information. You further agree
    to promptly update account and payment information, including email address, payment method, and payment card
    expiration date, so that we can complete your transactions and contact you as needed. We bill you through an online
    billing account. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at
    the end of any Subscription Period by providing at least 30 days prior notice. All payments shall be in U.S.
    dollars.</p>
  <p>You agree to pay all fees and taxes associated with your use of the Platform and your purchases, and you authorize
    us to charge your chosen payment provider for any such amounts upon making your purchase. Your payment method
    automatically will be charged at the start of each Subscription Period for the fees and taxes applicable to that
    period. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or
    received payment. We also reserve the right to refuse any order placed through the Platform.</p>
  <p>If, for any reason other than negligence on our part, full payment has not been received by us ten (10) days after
    the invoice date, then such unpaid amounts are subject to a finance charge of 2% per month on any outstanding
    balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in
    immediate termination of your access to the Platform.</p>
  <p><strong>RENEWAL AND CANCELLATION</strong></p>
  <p>Your account will automatically renew at the end of each Subscription Period, unless you provide written notice of
    non-renewal 30 days before the Subscription Period expires. Any cancellation will take effect at the end of the
    current paid Subscription Period. Upon cancellation, you will immediately discontinue all use of the Platform,
    except that upon request, we will provide you with limited access to the Services for a period not to exceed 30
    days, solely to enable you to retrieve your information from the Platform. We have no obligation to maintain your
    information after that period.</p>
  <p><strong>PROHIBITED ACTIVITIES</strong>&nbsp;</p>
  <p>You may not access or use the Platform for any purpose other than that for which we make the Platform available.
    The Platform may not be used in connection with any commercial endeavors except those that are specifically endorsed
    or approved by us. </p>
  <p>As a user of the Platform, you agree not to:</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Make any unauthorized use of the Platform, including collecting usernames
    and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or
    creating user accounts by automated means or under false pretenses. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Circumvent, disable, or otherwise interfere with security-related features of
    the Platform, including features that prevent or restrict the use or copying of any Content or enforce limitations
    on the use of the Platform and/or the Content contained therein.&nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Engage in unauthorized framing of or linking to the Platform. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Trick, defraud, or mislead us and other users, especially in any attempt to
    learn sensitive account information such as user passwords. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unless previously approved by us or as intended in the Platform, engage in
    any automated use of the system, such as using scripts to send comments or messages, or using any data mining,
    robots, or similar data gathering and extraction tools. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Interfere with, disrupt, or create an undue burden on the Platform or the
    networks or services connected to the Platform. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Attempt to impersonate another user or person or use the username of another
    user. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sell or otherwise transfer your account.&nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use the Platform as part of any effort to compete with us or otherwise use
    the Platform and/or the Content for any revenue-generating endeavor or commercial enterprise except as authorized by
    this agreement. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Decipher, decompile, disassemble, or reverse engineer any of the software
    comprising or in any way making up a part of the Platform.&nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Attempt to bypass any measures of the Platform designed to prevent or
    restrict access to the Platform, or any portion of the Platform.&nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delete the copyright or other proprietary rights notice from any Content.
    &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copy or adapt the Platform’s software, including but not limited to Flash,
    PHP, HTML, JavaScript, or other code. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload or transmit (or attempt to upload or to transmit) viruses, Trojan
    horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive
    text), that interferes with any party’s uninterrupted use and enjoyment of the Platform or modifies, impairs,
    disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Platform. &nbsp;
  </p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload or transmit (or attempt to upload or to transmit) any material that
    acts as a passive or active information collection or transmission mechanism, including without limitation, clear
    graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred
    to as “spyware” or “passive collection mechanisms” or “pcms”). &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Except as may be the result of standard search engine or Internet browser
    usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot,
    cheat utility, scraper, or offline reader that accesses the Platform, or using or launching any unauthorized script
    or other software. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
    Platform. &nbsp;</p>
  <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use the Platform in a manner inconsistent with any applicable laws or
    regulations. &nbsp;</p>
  <p><strong>&nbsp;</strong></p>
  <p><strong>YOUR INFORMATION IN OUR PLATFORM</strong></p>
  <p>As between you and us, you own all of the data, content and information you submit or that is uploaded, stored or
    sent to the Platform. You agree to only provide content or information that does not violate the law or rights
    (e.g., intellectual property, contract breaches, etc.) of any third party. You commit, to the best of your ability,
    to provide true, accurate, and complete information. Notwithstanding anything to the contrary in this agreement, we
    shall have the right to collect and analyze data and other information relating to the provision, use and
    performance of various aspects of the Services and related systems and technologies, provided such data is
    aggregated and de-identified, and Company will be free (during and after the term hereof) to (i) use such
    information and data to improve and enhance the Services and for other development, diagnostic and corrective
    purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in
    aggregate or other de-identified form in connection with its business. &nbsp; </p>
  <p>&nbsp;</p>
  <p><strong>SUBMISSIONS</strong></p>
  <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information
    regarding the Platform ("Submissions") provided by you to us are non-confidential and shall become our sole
    property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the
    unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without
    acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby
    warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You
    agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any
    proprietary right in your Submissions. &nbsp;</p>
  <p><strong>U.S. GOVERNMENT RIGHTS</strong></p>
  <p>Our services are “commercial items” as defined in Federal Acquisition Regulation (“FAR”) 2.101. If our services are
    acquired by or on behalf of any agency not within the Department of Defense (“DOD”), our services are subject to the
    terms of these Terms of Use in accordance with FAR 12.212 (for computer software) and FAR 12.211 (for technical
    data). If our services are acquired by or on behalf of any agency within the Department of Defense, our services are
    subject to the terms of these Terms of Use in accordance with Defense Federal Acquisition Regulation (“DFARS”)
    227.7202‑3. In addition, DFARS 252.227‑7015 applies to technical data acquired by the DOD. This U.S. Government
    Rights clause is in lieu of, and supersedes, any other FAR, DFARS, or other clause or provision that addresses
    government rights in computer software or technical data under these Terms of Use.</p>
  <p><strong>PLATFORM MANAGEMENT</strong></p>
  <p>We reserve the right, but not the obligation, to: (1) monitor the Platform for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, delete, or disable (to the extent technologically feasible) any of your information; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Platform or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Platform in a manner designed to protect our rights and property and to facilitate the proper functioning of the Platform.</p>
  <p><strong>TERM AND TERMINATION</strong></p>
  <p>These Terms of Use shall remain in full force and effect while you use the Platform. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE PLATFORM (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE PLATFORM OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. &nbsp;</p>
  <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
  <p><strong>MODIFICATIONS AND INTERRUPTIONS</strong></p>
  <p>We reserve the right to change, modify, or remove the contents of the Platform at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Platform. We also reserve the right to modify or discontinue all or part of the Platform without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Platform.  
    &nbsp;</p>
  <p>We shall use reasonable efforts consistent with prevailing industry standards to maintain the Platform in a manner which minimizes errors and interruptions in the Platform. The Platform may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by us or by third-party providers, or because of other causes beyond our reasonable control, but we shall use reasonable efforts to provide advance notice of any scheduled service disruption. HOWEVER, COMPANY DOES NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE PLATFORM.</p>
  <p><strong>GOVERNING LAW</strong></p>
  <p>These Terms of Use and your use of the Platform are governed by and construed in accordance with the laws of the Commonwealth of Virginia applicable to agreements made and to be entirely performed within the Commonwealth of Virginia, without regard to its conflict of law principles.  &nbsp;&nbsp;</p>
  <p><strong>DISPUTE RESOLUTION</strong></p>
  <p>The parties agree to attempt initially to resolve any claim, dispute or controversy arising under, out of or in connection with this agreement (each of the foregoing, individually, a “Dispute”) by conducting informal negotiations.</p>
  <p><strong>Binding Arbitration</strong></p>
  <p>If the parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding arbitration. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website www.adr.org. Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The arbitration may be conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in writing but need not provide a statement of reasons unless requested by either party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in Fairfax County, Virginia. Except as otherwise provided herein, the parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.</p>
  <p>If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state and federal courts located in Fairfax County, Virginia, and the parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to, venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Terms of Use.  &nbsp;&nbsp;&nbsp;</p>
  <p>If this provision is found to be illegal or unenforceable, then neither party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the parties agree to submit to the personal jurisdiction of that court.</p>
  <p><strong>Restrictions</strong></p>
  <p>The parties agree that any arbitration shall be limited to the Dispute between the parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>
  <p><strong>Exceptions to Arbitration</strong></p>
  <p>The parties agree that the following Disputes are not subject to the above provisions concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the parties agree to submit to the personal jurisdiction of that court.</p>
  <p><strong>CORRECTIONS</strong></p>
  <p>There may be information on the Platform that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Platform at any time, without prior notice.</p>
  <p><strong>DISCLAIMER</strong></p>
  <p>THE PLATFORM IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE PLATFORM AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE PLATFORM AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR (1) ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
  <p><strong>LIMITATIONS OF LIABILITY</strong></p>
  <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE six (6) mONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
  </p>
  <p><strong>INDEMNIFICATION</strong></p>
  <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Platform; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Platform with whom you connected via the Platform. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
  <p><strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></p>
  <p>Visiting the Platform, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Platform, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE PLATFORM. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means. &nbsp;</p>
  <p><strong>MISCELLANEOUS</strong></p>
  <p>These Terms of Use and any policies or operating rules posted by us on the Platform or in respect to the Platform constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all our rights and obligations to others at any time. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Terms of Use or use of the Platform. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</p>
  <p><strong>CONTACT US</strong></p>
  <p>In order to resolve a complaint regarding the Platform, to provide written notification, or to receive further information regarding use of the Platform, please contact us at: &nbsp;</p>
  <p><strong>Vinnik CPA, LLC. &nbsp;</strong></p>
  <span>11300 Rockville Pike&nbsp;</span><br>
  <span>Suite 712</span><br>
  <span>Rockville, MD 20852 &nbsp; &nbsp;</span>
  <p><strong><a href="mailto:info@vinnikcpa.com">info@vinnikcpa.com</a></strong></p>
  <p></p>
</div>