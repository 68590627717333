import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";

@Injectable({
    providedIn: 'root'
})
export class ConfigurationMockApi {
    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    registerHandlers() {
        this._fuseMockApiService
            .onGet('/configuration/reportTypes', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'The General Report'
                                },
                                {
                                    id: 2,
                                    name: 'The Trial Balance'
                                },
                                {
                                    id: 3,
                                    name: 'The Accounts Payable Aging'
                                },
                                {
                                    id: 4,
                                    name: 'The Accounts Receivable Aging'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onGet('/configuration/reportPeriods', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'This Month'
                                },
                                {
                                    id: 2,
                                    name: 'This Fiscal Quarter'
                                },
                                {
                                    id: 3,
                                    name: 'This Fiscal Year'
                                },
                                {
                                    id: 4,
                                    name: 'Last Week'
                                },
                                {
                                    id: 5,
                                    name: 'Last Month'
                                },
                                {
                                    id: 6,
                                    name: 'Last Fiscal Quarter'
                                },
                                {
                                    id: 7,
                                    name: 'Last Fiscal Year'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onGet('/configuration/clients', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'Client 1'
                                },
                                {
                                    id: 2,
                                    name: 'Client 2'
                                },
                                {
                                    id: 3,
                                    name: 'Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 '
                                },
                                {
                                    id: 4,
                                    name: 'Client 4'
                                },
                                {
                                    id: 5,
                                    name: 'Client 5'
                                },
                                {
                                    id: 6,
                                    name: 'Client 6'
                                },
                                {
                                    id: 7,
                                    name: 'Client 7'
                                },
                                {
                                    id: 8,
                                    name: 'Client 8'
                                },
                                {
                                    id: 9,
                                    name: 'Client 9'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onPost('/configuration/run', 1500)
            .reply(({request}) => {
                if (request.body.clientIds.length && request.body.reportTypeIds.length && request.body.reportPeriodId)
                {
                    return [
                        200,
                        {
                            messages: ["Report generation is successfull. You\'ll receive an email with it immediately"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Sent data is invalid"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onPost('/configuration/schedule', 1500)
            .reply(({request}) => {
                if (request.body.clientIds.length && request.body.reportTypeIds.length && request.body.reportPeriodId)
                {
                    return [
                        200,
                        {
                            messages: ["Report generation is successfull. You\'ll receive an email with it according the selected schedule"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Sent data is invalid"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onGet('/configuration/users', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            content: [
                                {id: 1, name: 'Hydrogen', lastLogin: new Date(2023, 10), status:'Active',action:true,accessControl:true},
                                {id: 2, name: 'Helium Helium Long name', lastLogin: new Date(), status:'Pending',action:false,accessControl:false},
                                {id: 3, name: 'Lithium', lastLogin: new Date(2016, 5, 4),firstName:'Volkova',lastName:'Anastasia',title:'test',email:'nastya@gmail.com',isAdmin:false,mobile:'+12344357679'},
                                {id: 4, name: 'Beryllium', lastLogin: new Date(2021, 9, 3)},
                                {id: 5, name: 'Boron', lastLogin: new Date(2023, 1)},
                                {id: 6, name: 'Carbon', lastLogin: new Date()},
                                {id: 7, name: 'Nitrogen', lastLogin: new Date()},
                                {id: 8, name: 'Oxygen', lastLogin: new Date()},
                                {id: 9, name: 'Fluorine', lastLogin: new Date()},
                                {id: 10, name: 'Neon', lastLogin: new Date()},
                                {id: 11, name: 'Carbon', lastLogin: new Date()},
                                {id: 12, name: 'Nitrogen', lastLogin: new Date()},
                                {id: 13, name: 'Oxygen', lastLogin: new Date()},
                                {id: 14, name: 'Fluorine', lastLogin: new Date()},
                                {id: 15, name: 'Neon', lastLogin: new Date()},
                            ],
                            pageable: {
                                pageNumber: 0,
                                pageSize: 10,
                            },
                            last: true,
                            totalPages: 1,
                            totalElements: 1,
                            first: true,
                            size: 10,
                            number: 0
                        }
                    ];
                }
            });

            
        this._fuseMockApiService
        .onGet('/configuration/user/permissions', 1500)
        .reply(() => {
            {
                return [
                    200,
                    {
                        model: [
                            {id: 1, name: 'Hydrogen', subName: 'Hydrogen', fullAccess:true,readOnly:false,noAccess:false,level:1},
                            {id: 2, name: 'Helium Helium Long name', subName: 'Hydrogen', fullAccess:false, readOnly:true, noAccess:false,level:3},
                            {id: 3, name: 'Lithium', subName: 'Hydrogen', fullAccess:false, readOnly:false, noAccess:true,level:2},
                            {id: 4, name: 'Hydrogen', subName: 'Hydrogen', fullAccess:true,readOnly:false,noAccess:false,level:1},
                            {id: 5, name: 'Helium Helium Long name', subName: 'Hydrogen', fullAccess:false, readOnly:true, noAccess:false,level:1},
                            {id: 6, name: 'Lithium', subName: 'Hydrogen', fullAccess:false, readOnly:false, noAccess:true,level:2},
                            {id: 7, name: 'Hydrogen', subName: 'Hydrogen', fullAccess:true,readOnly:false,noAccess:false,level:3},
                            {id: 8, name: 'Helium Helium Long name', subName: 'Hydrogen', fullAccess:false, readOnly:true, noAccess:false,level:3},
                            {id: 9, name: 'Lithium', subName: 'Hydrogen', fullAccess:false, readOnly:false, noAccess:true,level:2},
                            {id: 10, name: 'Hydrogen', subName: 'Hydrogen', fullAccess:true,readOnly:false,noAccess:false,level:1},
                            {id: 11, name: 'Helium Helium Long name', subName: 'Hydrogen', fullAccess:false, readOnly:true, noAccess:false,level:3},
                            {id: 12, name: 'Lithium', subName: 'Hydrogen', fullAccess:false, readOnly:false, noAccess:true,level:1},
                        ]
                    }
                ];
            }
        });


        this._fuseMockApiService
            .onDelete('/configuration/users', 1500)
            .reply(({request}) => {
                if (request.body.reportIds.length !== 1)
                {
                    return [
                        200,
                        {
                            messages: ["Reports deletion is successfull. You\'ll not receive emails with deleted reports anymore"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Deletion error"],
                        success: false
                    }
                ];
            });
    }
}