import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ConstsHelper } from '../helpers/consts-helper';
import { Client, DeleteReportsDto, ImmediateReport, ReportPeriod, ReportType, ScheduledReport } from '../models/report.model';
import { Response, ListResponse } from '../models/response.model';
import { UsersBaseInfo } from '../models/configuration.model';
import { catchError, map, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorText } from 'app/i18/errorsText';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly _baseUrl = environment.apiUrl + ConstsHelper.urlConfiguration;
  private readonly _url = environment.apiUrl;

  constructor(
    private _http: HttpClient,
    private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, type: number) {
    this._snackBar.open(message, action, {
      panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
      duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
    });
}
  getReportTypes() {
      return this._http.get<ListResponse<ReportType>>(`${this._baseUrl}/reportTypes`);
  }

  getReportPeriods() {
      return this._http.get<ListResponse<ReportPeriod>>(`${this._baseUrl}/reportPeriods`);
  }

  getClients() {
    return this._http.get<ListResponse<Client>>(`${this._baseUrl}/clients`);
  }

  addNewUser(data) {
    let tenantId = JSON.parse(localStorage.getItem('user'))?.isRoot == false ? 
    JSON.parse(localStorage.getItem('user'))?.tenantId : 'root';
    return this._http.post<Response>(`${this._url}/tenant/${tenantId}/user`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
      //  this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateUser(data) {
    let tenantId = JSON.parse(localStorage.getItem('user'))?.isRoot == false ? 
    JSON.parse(localStorage.getItem('user'))?.tenantId : 'root';
    return this._http.put<Response>(`${this._url}/tenant/${tenantId}/user`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
       // this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  deleteUsers(data) {
    let tenantId = JSON.parse(localStorage.getItem('user'))?.isRoot == false ? 
    JSON.parse(localStorage.getItem('user'))?.tenantId : 'root';
    return this._http.delete<Response>(`${this._url}/tenant/${tenantId}/user?userIds=${data}`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
       // this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  sendInvite(userId) {
    let tenantId = JSON.parse(localStorage.getItem('user'))?.isRoot == false ? 
    JSON.parse(localStorage.getItem('user'))?.tenantId : 'root';
    return this._http.post<Response>(`${this._url}/tenant/${tenantId}/resend-invitation/user/${userId}`, {}).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
       // this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }
 
  getUsers(tenantId,params) { 
    if(!params?.sortColumnId && !params?.sortName){
      return this._http.get<ListResponse<any>>(`${this._url}/tenant/${tenantId}/user?page=${params.pageNum}&size=${params.size}&nameLast=${params.lastName}&nameFirst=${params.firstName}`).pipe(
        map(response => {
          return response;
        }),
        catchError((error: any) => {
          this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
          return throwError(error);
        })
      );
    } else{
      return this._http.get<ListResponse<any>>(`${this._url}/tenant/${tenantId}/user?page=${params.pageNum}&size=${params.size}&nameLast=${params.lastName}&nameFirst=${params.firstName}&${params.sortName}=${params.sortColumnId}`).pipe(
        map(response => {
          return response;
        }),
        catchError((error: any) => {
          this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
          return throwError(error);
        })
      );
    }
  } 

  getUsersAccounts() { //
    return this._http.get<ListResponse<any>>(`${this._baseUrl}/cch`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );;
  }
  
  getCorporatesAccounts() {
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/cch/configuration/corporate`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  
  getIndividualAccounts() {
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/cch/configuration/individual`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateCorporateAccount(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/cch/configuration/corporate`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateIndividualAccount(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/cch/configuration/individual`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  putUserAccount(data) { //
    return this._http.put<ListResponse<any>>(`${this._baseUrl}/cch`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );;
  }

  getAllPermissions(tenantId) { //
    let isRoot = JSON.parse(localStorage.getItem('user'))?.isRoot
    return this._http.get<ListResponse<any>>(`${this._url}/tenant/${tenantId}/permission?root=${isRoot}`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );;
  }

  getUserPermissions(id) { //
    return this._http.get<ListResponse<any>>(`${this._baseUrl}/user/permissions`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );;
  }
  delete(reportIds: number[]) {
    return this._http.delete<Response>(`${this._baseUrl}`, {body: new DeleteReportsDto(reportIds)});
  }
  
  getLegend(component_code) {
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/legend?component=${component_code}`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateLegend(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/config/legend`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateStateNexus(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/avalara/nexus/report/root/status`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateStateSOSStatus(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/sos/status`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  getAvalara() {
    return this._http.get<ListResponse<any>>(`${this._url.replace('/rbac', '')}/avalara/configuration`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

  updateAvalara(data) {
    return this._http.put<ListResponse<any>>(`${this._url.replace('/rbac', '')}/avalara/configuration`, data).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any) => {
        this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
        return throwError(error);
      })
    );
  }

}
