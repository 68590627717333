import { Component, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
// import { registerLicense } from '@syncfusion/ej2-base';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private _bnIdle: BnNgIdleService,
        private dialogRef: MatDialog,
        private _authService: AuthService
    ) {}

    ngOnInit(): void {
        // registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV1dX2ZWfFN0QXNYf1RwcF9HY0wgOX1dQl9nSX9Rf0RjWH5ecXRdR2U=');
        // this._bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
        //     let token = (this._authService.jwtToken !== null && this._authService.jwtToken !== undefined) ? this._authService.jwtToken : JSON.parse(localStorage.getItem('token'));
        //     if (isTimedOut || token) {
        //         this._authService.signOut('info');
        //         this.dialogRef.closeAll();
        //     }
        // });
    }
}
