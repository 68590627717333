import { AuthMockApi } from 'app/mock-api/auth/api';
import { IconsMockApi } from 'app/mock-api/icons/api';
import { ReportMockApi } from './reports/api';
import { ReturnMockApi } from './returns/api';
import { ConfigurationMockApi } from './configuration/api';

export const mockApiServices = [
    AuthMockApi,
    IconsMockApi,
    ReportMockApi,
    ReturnMockApi,
    ConfigurationMockApi
];
