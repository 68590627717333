<div class="prose text-gray-700 max-w-none p-[2rem]">
  <h1>Privacy Policy</h1>
  <p><strong>Last updated January 01, 2023</strong></p>
  <p>VINNIK CPA, LLC. (“we” or “us” or “our”) respects the privacy of individuals who visit our web site and individuals
    who use our Platform services, whether personally or on behalf of a company or other legal entity (collectively
    “you”, “your” or, the “user”). This Privacy Policy explains how we collect, use, disclose, and safeguard your
    information with regard to your use of our website, VINNIK CPA Platform (the “Platform”) and any other related
    services, programs, media form, media channel, mobile platform, or mobile application related or connected thereto
    (collectively, the “Services”). Please read this Privacy Policy carefully.</p>
  <h2>Information Collected</h2>
  <p>When expressing an interest in obtaining additional information about us or our Services, or registering to use the
    Services, we may require or request you or your Client Administrator to provide us with personal contact
    information, such as name, company name, title, address, phone number, and email address. You are under no
    obligation to provide us with personal information of any kind; however your refusal to do so may prevent you from
    using some or all of the Services. When visitors apply for a job with us, we may also require you to submit
    additional personal information as well as a resume or curriculum vitae.</p>
  <h2>Use of Your Information</h2>
  <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you to:</p>
  <ul>
    <li>Increase the efficiency and operation of the Platform.</li>
    <li>Notify you of updates to the Platform.</li>
    <li>Create and manage your account.</li>
    <li>Email you regarding your account.</li>
    <li>Enable user-to-user communications.</li>
    <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
    <li>Deliver targeted advertising, newsletters, and promotions, and other information regarding our Platform and
      mobile application to you.</li>
    <li>Fulfill and manage purchases, and other transactions related to the Platform.</li>
    <li>Perform other business activities as needed.</li>
    <li>Resolve disputes and troubleshoot problems.</li>
    <li>Respond to product and user service requests.</li>
  </ul>
  <h2>Disclosure of Your Information</h2>
  <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
  <p><strong>By Law or to Protect Rights</strong><br>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</p>
  <p><strong>Third-Party Service Providers</strong><br>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>
  <p><strong>Marketing Communications</strong><br>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</p>
  <p><strong>Affiliates, Business Partners and Other Third Parties</strong><br>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us. We may share your information with our business partners to offer you certain products, services or promotions. We may share your information with advertisers and investors for the purpose of conducting general business analysis.</p>
  <h2>Tracking Technologies</h2>
  <p><strong>Cookies and Web Beacons</strong><br>We may use cookies, web beacons, tracking pixels, and other tracking technologies to help customize the Services and improve your experience. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Services.</p>
  <p><strong>Log Files, IP Addresses, URLs and Other Data</strong><br>We gather certain information automatically to analyze trends in the aggregate and administer our Services. This information may include your Internet Protocol (IP) address (or the proxy server you use to access the World Wide Web), device and application identification numbers, your location, your browser type, your Internet service provider and/or mobile carrier, the pages and files you viewed, your searches, your operating system and system configuration information, and date/time stamps associated with your usage. Due to Internet communications standards, when you visit or use our Services, we automatically receive the URL of the website from which you came and the website to which you go when you leave our Website. This information is used to analyze overall trends, to help us improve our Services, to track and aggregate non-personal information, and to provide the Services.</p>
  <h2>Third-Party Web Sites</h2>
  <p>The Services may contain links to third-party web sites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave our Services, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party web site, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that web site, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other web sites, services or applications that may be linked to or from our Services.</p>
  <h2>Security of Your Information</h2>
  <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information. For users, we recommend safeguarding your password, as it is one of the easiest ways you can manage the security of your account – remember that if you lose control over your password, you may lose control over your personal information.</p>
  <h2>Controls For Do-Not-Track Features</h2>
  <p>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
  <h2>Options Regarding Your Information</h2>
  <p><strong>Account Information</strong></p>
  <p>You may at any time review or change the information in your account or terminate your user account through your Account Administrator.</p>
  <p>Upon a request by you or your Account Administrator to terminate your user account, we will deactivate or delete your account and personal information from our active databases. However, some information may be retained in our files to track historical activity, prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
  <p><strong>Changes to this Statement</strong></p>
  <p>We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes we will provide notice on this website prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
  <h2>Contact Us</h2>
  <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
  <p><strong>VINNIK CPA, LLC.</strong><br></p>
    11300 Rockville Pike<br>Suite 712<br>Rockville, MD 20852<br>
    <p>Phone:<a role="link" tabindex="0" href="tel:(301)284-3515">(301) 284-3515</a></p>
    <p><a href="mailto:info@vinnikcpa.com">info@vinnikcpa.com</a></p>
</div>