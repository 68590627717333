import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";

@Injectable({
    providedIn: 'root'
})
export class ReportMockApi {
    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    registerHandlers() {
        this._fuseMockApiService
            .onGet('/report/reportTypes', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'The General Report'
                                },
                                {
                                    id: 2,
                                    name: 'The Trial Balance'
                                },
                                {
                                    id: 3,
                                    name: 'The Accounts Payable Aging'
                                },
                                {
                                    id: 4,
                                    name: 'The Accounts Receivable Aging'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onGet('/report/reportPeriods', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'This Month'
                                },
                                {
                                    id: 2,
                                    name: 'This Fiscal Quarter'
                                },
                                {
                                    id: 3,
                                    name: 'This Fiscal Year'
                                },
                                {
                                    id: 4,
                                    name: 'Last Week'
                                },
                                {
                                    id: 5,
                                    name: 'Last Month'
                                },
                                {
                                    id: 6,
                                    name: 'Last Fiscal Quarter'
                                },
                                {
                                    id: 7,
                                    name: 'Last Fiscal Year'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onGet('/report/clients', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {
                                    id: 1,
                                    name: 'Client 1'
                                },
                                {
                                    id: 2,
                                    name: 'Client 2'
                                },
                                {
                                    id: 3,
                                    name: 'Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 Client 3 '
                                },
                                {
                                    id: 4,
                                    name: 'Client 4'
                                },
                                {
                                    id: 5,
                                    name: 'Client 5'
                                },
                                {
                                    id: 6,
                                    name: 'Client 6'
                                },
                                {
                                    id: 7,
                                    name: 'Client 7'
                                },
                                {
                                    id: 8,
                                    name: 'Client 8'
                                },
                                {
                                    id: 9,
                                    name: 'Client 9'
                                }
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onPost('/report/run', 1500)
            .reply(({request}) => {
                if (request.body.clientIds.length && request.body.reportTypeIds.length && request.body.reportPeriodId)
                {
                    return [
                        200,
                        {
                            messages: ["Report generation is successfull. You\'ll receive an email with it immediately"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Sent data is invalid"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onPost('/report/schedule', 1500)
            .reply(({request}) => {
                if (request.body.clientIds.length && request.body.reportTypeIds.length && request.body.reportPeriodId)
                {
                    return [
                        200,
                        {
                            messages: ["Report generation is successfull. You\'ll receive an email with it according the selected schedule"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Sent data is invalid"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onGet('/report', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {id: 1, name: 'Hydrogen', creationDate: new Date(2023, 10),returnStep:'2',status:'In progress',subID:'3564623642738',refund:'56454',action:'link...'},
                                {id: 2, name: 'Helium Long name ', creationDate: new Date()},
                                {id: 3, name: 'Lithium', creationDate: new Date(2016, 5, 4)},
                                {id: 4, name: 'Beryllium', creationDate: new Date(2021, 9, 3)},
                                {id: 5, name: 'Boron', creationDate: new Date(2023, 1)},
                                {id: 6, name: 'Carbon', creationDate: new Date()},
                                {id: 7, name: 'Nitrogen', creationDate: new Date()},
                                {id: 8, name: 'Oxygen', creationDate: new Date()},
                                {id: 9, name: 'Fluorine', creationDate: new Date()},
                                {id: 10, name: 'Neon', creationDate: new Date()},
                                {id: 11, name: 'Carbon', creationDate: new Date()},
                                {id: 12, name: 'Nitrogen', creationDate: new Date()},
                                {id: 13, name: 'Oxygen', creationDate: new Date()},
                                {id: 14, name: 'Fluorine', creationDate: new Date()},
                                {id: 15, name: 'Neon', creationDate: new Date()},
                            ]
                        }
                    ];
                }
            });

            this._fuseMockApiService
            .onGet('/report/corporate', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {id: 1, name: 'test 2', creationDate: new Date(2023, 10),returnStep:'2',status:'In progress',subID:'3564623642738',refund:'56454',action:'link...'},
                                {id: 2, name: 'Helium Long name ', creationDate: new Date()},
                                {id: 3, name: 'Lithium', creationDate: new Date(2016, 5, 4)},
                                {id: 4, name: 'Beryllium', creationDate: new Date(2021, 9, 3)},
                                {id: 5, name: 'Boron', creationDate: new Date(2023, 1)},
                                {id: 6, name: 'Carbon', creationDate: new Date()},
                                {id: 7, name: 'Nitrogen', creationDate: new Date()},
                                {id: 8, name: 'Oxygen', creationDate: new Date()},
                                {id: 9, name: 'Fluorine', creationDate: new Date()},
                                {id: 10, name: 'Neon', creationDate: new Date()},
                                {id: 11, name: 'Carbon', creationDate: new Date()},
                                {id: 12, name: 'Nitrogen', creationDate: new Date()},
                                {id: 13, name: 'Oxygen', creationDate: new Date()},
                                {id: 14, name: 'Fluorine', creationDate: new Date()},
                                {id: 15, name: 'Neon', creationDate: new Date()},
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onDelete('/report', 1500)
            .reply(({request}) => {
                if (request.body.reportIds.length !== 1)
                {
                    return [
                        200,
                        {
                            messages: ["Reports deletion is successfull. You\'ll not receive emails with deleted reports anymore"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Deletion error"],
                        success: false
                    }
                ];
            });
    }
}