import { FuseNavigationItem } from "@fuse/components/navigation";

export const navigationData: FuseNavigationItem[] = [
    {
    id      : 'dashboard',
    title   : 'Dashboard',
    permissions: '',
    type    : 'basic',
    link : '/dashboard',
    isMainComponent: false,
    icon    : 'mat_outline:dashboard'
    },
    {
        id      : 'secretary_state',
        title   : 'Secretary of State',
        permissions: 'sos',
        type    : 'collapsable',
        isMainComponent: true,
        icon    : 'mat_outline:account_balance',
        children: [
            {
                id   : 'secretary_state.status',
                title: 'Status',
                permissions: 'sos_status',
                type : 'basic',
                isMainComponent: false,
                link : 'sos/status'
            },
            {
                id   : 'secretary_state.check_sos',
                title: 'Check SOS',
                permissions: 'sos_check',
                type : 'basic',
                isMainComponent: false,
                link : 'sos/check'
            },
            {
                id   : 'secretary_state.reports',
                title: 'Reports',
                permissions: 'sos_report',
                type : 'basic',
                isMainComponent: false,
                link : 'sos/reports'
            }
        ]
    },
    {
        id      : 'tax_returns',
        title   : 'Tax Returns',
        type    : 'collapsable',
        permissions: 'tax_returns',
        isMainComponent: true,
        icon    : 'mat_outline:request_quote',
        children: [
            {
                id   : 'tax_returns.status',
                title: 'Status',
                type : 'basic',
                permissions: 'tax_returns_status',
                isMainComponent: false,
                link : 'tax-returns/status'
            },
            {
                id   : 'tax_returns.returns',
                title: 'Returns',
                type : 'basic',
                permissions: 'tax_returns_corporate/tax_returns_individual',
                isMainComponent: false,
                // link : 'tax-returns/corporates'
                link : 'tax-returns/returns'
            },
            // {
            //     id   : 'tax_returns.individual_returns',
            //     title: 'Individual Returns',
            //     type : 'basic',
            //     permissions: 'tax_returns_individual',
            //     link : 'tax-returns/individuals'
            // }
        ]
    },
    {
        id      : 'sales_tax',
        title   : 'Sales Tax',
        type    : 'collapsable',
        permissions: 'tax_sales',
        isMainComponent: true,
        icon    : 'mat_outline:receipt_long',
        children: [
            {
                id   : 'sales_tax.nexus_status',
                title: 'Nexus Status Report',
                type : 'basic',
                permissions: 'tax_sales_nexus',
                isMainComponent: false,
                link : '/sales/nexus-status-report'
            },
            {
                id   : 'sales_tax.sales_data_export',
                title: 'Sales Data Export',
                type : 'basic',
                permissions: 'tax_sales_export',
                isMainComponent: false,
               // link : ''
                link : '/sales/sales-data-export'
            },
            {
                id   : 'sales_tax.status',
                title: 'Sales Tax Liability Report',
                type : 'basic',
                permissions: 'tax_sales_status',
                isMainComponent: false,
                link : '/sales/status'
            },
            {
                id   : 'sales_tax.report_sales_tax',
                title: 'Upload Sales Info',
                type : 'basic',
                permissions: 'tax_sales_report',
                isMainComponent: false,
                link : '/sales/report-sales-tax'
            },
            {
                id   : 'sales_tax.find_sales_tax',
                title: 'Find Sales Tax Rate',
                type : 'basic',
                permissions: 'tax_sales_find',
                isMainComponent: false,
                link : '/sales/find-sales-tax'
            }
        ]
    },
    {
        id      : 'quick_books',
        title   : 'QuickBooks Reports',
        type    : 'collapsable',
        permissions: 'qb',
        isMainComponent: true,
        icon    : 'mat_outline:query_stats',
        children: [
            {
                id   : 'quick_books.connect_account',
                title: 'Connect Account',
                type : 'basic',
                permissions: 'qb_company_management',
                isMainComponent: false,
                link : '/report/connect-account'
            },
            {
                id   : 'quick_books.status',
                title: 'Status',
                type : 'basic',
                permissions: 'qb_reports_status',
                isMainComponent: false,
                link : 'report/status'
            },
            {
                id   : 'quick_books.execute_reports',
                title: 'Execute Reports',
                type : 'basic',
                permissions: 'qb_reports_execute',
                isMainComponent: false,
                link : 'report/execute-reports'
            }
        ]
    },
    {
        id      : 'entities_management',
        title   : 'Entities Management',
        type    : 'collapsable',
        permissions: 'entities',
        isMainComponent: true,
        icon    : 'mat_outline:apartment',
        children: [
            // {
            //     id   : 'entities_management.manage_companies',
            //     title: 'Manage Companies',
            //     type : 'basic',
            //     permissions: 'company_management',
            //     link : '/access/companies'
            // },
            {
                id   : 'entities_management.corporations',
                title: 'Corporations',
                type : 'basic',
                permissions: 'entities_corporations',
                isMainComponent: false,
                link : '/corporations/companies'
            },
            {
                id   : 'entities_management.individuals',
                title: 'Individuals',
                type : 'basic',
                permissions: 'entities_individuals',
                isMainComponent: false,
                link : '/individuals/users'
            }
        ]
    },
    {
        id      : 'configuration',
        title   : 'System configuration',
        type    : 'collapsable',
        permissions: 'system',
        isMainComponent: true,
        icon    : 'heroicons_outline:cog',
        children: [
            {
                id   : 'configuration.users',
                title: 'Users',
                type : 'basic',
                permissions: 'system_users',
                isMainComponent: false,
                link : '/configuration/users'
            },
            {
                id   : 'configuration.avalara',
                title: 'Avalara Configuration',
                type : 'basic',
                permissions: 'system_config_avalara',
                isMainComponent: false,
                link : '/configuration/avalara'
            },
            {
                id   : 'configuration.cch_configuration',
                title: 'CCH Configuration',
                type : 'basic',
                permissions: 'system_config_cch/system_config_cch_template',
                isMainComponent: false,
                link : '/configuration/cch'
            }, 
            {
                id   : 'configuration.tools_management',
                title: 'Tools Management',
                type : 'basic',
                permissions: 'system_tools',
                link : '/configuration/tools'
            },
            {
                id   : 'configuration.users',
                title: 'Tenant Management',
                type : 'basic',
                permissions: 'company_management',
                isMainComponent: false,
                link : '/configuration/tenants'
            }
            // {
            //     id   : 'configuration.avalara_configuration',
            //     title: 'Avalara Configuration',
            //     type : 'basic',
            //     permissions: 'system_config_awalara',
            //     link : '/report/management'
            // }
        ]
    },
    // {
    //     id      : 'lists',
    //     title   : 'Lists',
    //     type    : 'collapsable',
    //     permissions: '',
    //     icon    : 'mat_outline:wysiwyg',
    //     children: [
    //         {
    //             id   : 'lists.individuals',
    //             title: 'Individuals',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/list/individuals'
    //         },
    //         {
    //             id   : 'lists.corporates',
    //             title: 'Corporates',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/list/corporates'
    //         }
    //     ]
    // },
    // {
    //     id      : 'returns',
    //     title   : 'Returns',
    //     type    : 'collapsable',
    //     permissions: '',
    //     icon    : 'mat_outline:request_page',
    //     children: [
    //         {
    //             id   : 'returns.individuals',
    //             title: 'Individuals',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/return/individuals'
    //         },
    //         {
    //             id   : 'returns.corporates',
    //             title: 'Corporates',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/return/corporates'
    //         }
    //     ]
    // },
    // {
    //     id      : 'reports',
    //     title   : 'Reports',
    //     type    : 'collapsable',
    //     permissions: '',
    //     icon    : 'heroicons_outline:document-report',
    //     children: [
    //         {
    //             id   : 'reports.creation',
    //             title: 'Generation',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/report/generation'
    //         },
    //         {
    //             id   : 'returns.management',
    //             title: 'Management',
    //             type : 'basic',
    //             permissions: '',
    //             link : '/report/management'
    //         }
    //     ]
    // },
    // {
    //     id   : 'fieldsMapping',
    //     title: 'Fields Mapping',
    //     type : 'basic',
    //     link : '',
    //     icon : 'heroicons_outline:view-grid'
    // },
    // {
    //     id   : 'systemConfiguration',
    //     title: 'System Configuration',
    //     type : 'basic',
    //     link : '',
    //     icon : 'heroicons_outline:cog'
    // },
];