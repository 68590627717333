import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { DialogResult } from 'app/core/models/helper.model';
import { AlertService } from 'app/core/services/alert.service';
import { UsersService } from 'app/core/services/configuration.service';
import { SalesService } from 'app/core/services/sales.service';
import { SharedModule } from 'app/core/shared.module';
import { errorText } from 'app/i18/errorsText';

@Component({
  selector: 'app-legend-dialog',
  templateUrl: './legend-dialog.component.html',
  styleUrls: ['./legend-dialog.component.scss'],
  animations : fuseAnimations,
  standalone: true,
  imports: [SharedModule],
})
export class LegendDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LegendDialogComponent, any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _configService: UsersService,
    private _alertService: AlertService
  ) {}

  public isShowForm: boolean = true;
  public isUpdateUser: boolean = false;
  public sendForm: boolean = false;
  statusForm: FormGroup;

  ngOnInit() {
    this.statusForm = this._formBuilder.group({
      good_status: [this.data.legend.good ? this.data.legend.good : '', Validators.required],
      unknown_status: [this.data.legend.unknown ? this.data.legend.unknown : '', Validators.required],
      bad_status: [this.data.legend.bad ? this.data.legend.bad : '', Validators.required],
      not_status: [this.data.legend.na ? this.data.legend.na : '', Validators.required]
    })
}

onCancel() {
  let dialogResult = <DialogResult>{
    isSuccess: false
  }
  this.dialogRef.close(dialogResult);
}  

openSnackBar(message: string, action: string, type: number) {
  this._snackBar.open(message, action, {
    panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
    duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
  });
}


onSave() {
  this.sendForm = true;
  
  if (this.statusForm.invalid) {
    this.statusForm.markAllAsTouched();
    return false
  }

  let data = { 
    component: this.data.type == 'SOS_status' ? 'sos_status' : 'tax_sales_nexus',
    legend: {
      good: this.statusForm.value.good_status,
      bad: this.statusForm.value.bad_status,
      unknown: this.statusForm.value.unknown_status,
      na: this.statusForm.value.not_status,
    }
  }
  
  this._configService.updateLegend(data).subscribe((res: any) => {
    let dialogResult = <DialogResult>{
      isSuccess: true,
      message: 'Legend successfully updated'
    }
    this.dialogRef.close(dialogResult);
    },
    (err)=>{
      this.openSnackBar(errorText.errorMessage(err.code),'Cancel', 2);
  });
}


}