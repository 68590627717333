import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";

@Injectable({
    providedIn: 'root'
})
export class ReturnMockApi {
    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    registerHandlers() {
        this._fuseMockApiService
            .onGet('/return/corporates', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {id: 1, name: 'Hydrogen', creationDate: new Date(2023, 10), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 2, name: 'Helium Long name Long name Long name Long name Long name Long name Long name Long name Helium Long name Long name Long name Long name Long name Long name Long name Long name', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 3, name: 'Lithium', creationDate: new Date(2016, 5, 4), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 4, name: 'Beryllium', creationDate: new Date(2021, 9, 3), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 5, name: 'Boron', creationDate: new Date(2023, 1), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 6, name: 'Carbon', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 7, name: 'Nitrogen', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 8, name: 'Oxygen', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 9, name: 'Fluorine', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 10, name: 'Neon', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 11, name: 'Carbon', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 12, name: 'Nitrogen', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 13, name: 'Oxygen', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 14, name: 'Fluorine', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 15, name: 'Neon', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onDelete('/return/corporates', 1500)
            .reply(({request}) => {
                if (request.body.returnIds.length !== 1)
                {
                    return [
                        200,
                        {
                            messages: ["Returns deletion is successfull"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Deletion error"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onGet('/return/fileAccess', 1500)
            .reply(({request}) => {
                if (request.params.get('fileLink'))
                {
                    return [
                        200,
                        {
                            messages: ["Ok"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Access denied"],
                        success: false
                    }
                ];
            });

        this._fuseMockApiService
            .onGet('/return/individuals', 1500)
            .reply(() => {
                {
                    return [
                        200,
                        {
                            model: [
                                {id: 1, name: 'Hydrogen', creationDate: new Date(2023, 10), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 2, name: 'Helium Long name Long name Long name Long name Long name Long name Long name Long name Helium Long name Long name Long name Long name Long name Long name Long name Long name', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 3, name: 'Lithium', creationDate: new Date(2016, 5, 4), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 4, name: 'Beryllium', creationDate: new Date(2021, 9, 3), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 5, name: 'Boron', creationDate: new Date(2023, 1), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 6, name: 'Carbon', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 7, name: 'Nitrogen', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 8, name: 'Oxygen', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 9, name: 'Fluorine', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 10, name: 'Neon', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 11, name: 'Carbon', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 12, name: 'Nitrogen', creationDate: new Date(), returnStep: 2, latestFileLink: 'https://some_link'},
                                {id: 13, name: 'Oxygen', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                                {id: 14, name: 'Fluorine', creationDate: new Date(), returnStep: 3, latestFileLink: 'https://some_link'},
                                {id: 15, name: 'Neon', creationDate: new Date(), returnStep: 1, latestFileLink: 'https://some_link'},
                            ]
                        }
                    ];
                }
            });

        this._fuseMockApiService
            .onDelete('/return/individuals', 1500)
            .reply(({request}) => {
                if (request.body.returnIds.length !== 1)
                {
                    return [
                        200,
                        {
                            messages: ["Returns deletion is successfull"],
                            success: true
                        }
                    ];
                }

                return [
                    400,
                    {
                        messages: ["Deletion error"],
                        success: false
                    }
                ];
            });
    }
}