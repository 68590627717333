import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, Subject, switchMap, throwError } from 'rxjs';
import { Login, ResetPassword } from '../models/auth.model';
import { Params, Router } from '@angular/router';
import { ConstsHelper } from '../helpers/consts-helper';
import { environment } from 'environments/environment';
import { FuseAlertType } from '@fuse/components/alert';
import { JwtResponse } from '../models/response.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorText } from 'app/i18/errorsText';

@Injectable()
export class AuthService {
    public jwtToken?: string | null;
    public jwtTokenStorage: any;

    private readonly _baseUrl = environment.apiUrl + ConstsHelper.urlAuth;
    public isLogOut = new Subject();

    constructor(
        private _http: HttpClient,
        private _snackBar: MatSnackBar,
        private _router: Router
    ) {}
  
    openSnackBar(message: string, action: string, type: number) {
        this._snackBar.open(message, action, {
          panelClass: type == 1 ? 'app-notification-success' : 'app-notification-error',
          duration: 5 * 1000, horizontalPosition: 'end', verticalPosition: 'top',
        });
    }
    resetPassword(resetPasswordForm) {
        return this._http.post(`${this._baseUrl.replace('/rbac', '')}/forgot-password`, resetPasswordForm).pipe(
            map(response => {
              return response;
            }),
            catchError((error: any) => {
              this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
              return throwError(error);
            })
          );
    }

    
    restorePassword(resetPasswordForm) {
        return this._http.put(`${this._baseUrl.replace('/rbac', '')}/reset-password`, resetPasswordForm).pipe(
            map(response => {
              return response;
            }),
            catchError((error: any) => {
              this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
              return throwError(error);
            })
          );
    }

    signIn(form: Login) {
        return this._http.post(`${this._baseUrl.replace('/rbac', '')}/login`, form).pipe(
            map(response => {
              return response;
            }),
            catchError((error: any) => {
              this.openSnackBar(errorText.errorMessage(error.code),'Cancel',2)
              return throwError(error);
            })
          );
        // .pipe(
        //     switchMap((response: any) => {
        //         return of(response);
        //     }),
        //     catchError(err => of('error',  this.openSnackBar(errorText.errorMessage(err.code),'Cancel',2)))
        // );
    }

    signOut(alertType: FuseAlertType) {
        return this._http.post(`${this._baseUrl.replace('/rbac', '')}/logout`,{})
        .subscribe(res => {
            this.removeJwt();
            this.isLogOut.next(true);
            let message = alertType === 'success'
                ? 'Logout successfull'
                : alertType === 'error'
                    ? 'Access denied. Relogin please'
                    : 'Your session expired due inactivity. Relogin please';
            let type = alertType;
            let params: Params = {
                message: message,
                type: type
            }
            this._router.navigate(['/auth/sign-in'], { queryParams: params });
        },(err) => {
            this.removeJwt();
           // location.reload();
            this.openSnackBar(errorText.errorMessage(err.code),'Cancel',2);
          });
    }

    parseJwtResponse(jwtResponse: JwtResponse) {
        this.removeJwt();
        this.jwtToken = jwtResponse?.access_token;
        localStorage.setItem(ConstsHelper.USER_TOKEN, JSON.stringify(jwtResponse?.access_token));
    }
    
    removeJwt() {
        localStorage.removeItem(ConstsHelper.USER_TOKEN);
        localStorage.removeItem('autoRefresh');
        localStorage.removeItem('user');
        this.jwtToken = null;
    }
    
    private _initialize = (() => {
        this.jwtTokenStorage = JSON.parse(
          localStorage.getItem(ConstsHelper.USER_TOKEN) || 'null'
        );
        this.jwtToken = this.jwtTokenStorage
          ? this.jwtTokenStorage.access_token
          : null;
    })();
}
