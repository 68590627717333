export const en = {
    //1XXX base
    1000: 'General Failure',
    1001: 'Unauthorized',
    1003: 'Access Denied',
    1004: 'Object Not Found',
    1011: 'AccessToken validation failure',
    //2XXX RBAC
    2000: 'General RBAC failure',
    2001: 'Unauthorized',
    2003: 'Access Denied',
    2004: 'Object not found',
    2011: 'You have been logged out. Please re-login to the system',// 2011: 'Token Expired',
    2012: 'General error',// 2012: 'Token Invalid',
    2024: 'User not found',
    2027: 'You can’t delete yourself',
    2028: 'Cannot set the same password!',
    2034: 'Tenant Not Found',
    2035: 'Same website url for tenant is already exists',
    2038: 'Tenant`s website url not found',
    2039: 'Tenant with the same name already exists',
    2041: 'Tenant`s URL prefix not found',
    2042: 'Tenant with the same URL prefix already exists',
    2044: 'Invitation Not Found',
    2045: 'Wrong Password',
    2046:'Tenant`s GoogleDrive folder is inaccessible',
    2050: 'Login failure',
    2051: 'User was deactivated',
    2052: 'Tenant was deactivated',
    2053: 'Email not sent',
    2054: 'Email is already taken',
    2055: 'You cannot login as you company’s account was not activated yet. Please reach out to your Admin to address this issue',
    2056:'You cannot login as you company’s account was suspended. Please reach out to your Admin to address this issue',
    //3XXX QBO
    3000: 'General QBO failure',
    3001: 'Unauthorized',
    3003: 'Access Denied',
    3004: 'Object Not Found',
    3011: 'Token Expired',
    3012:'Token Invalid',
    3034: 'Tenant not found',
    3100: 'Schedule Invalid',
    //4XXX Avalara
    4000: 'General Avalara failure',
    4004: 'Object Not Found',
    4005: 'Cannot save file',
    4200: 'Avalara credentials not found',
    //5XXX CCH
    5004: 'Object Not Found',
    5101: 'CCH Folder not found',
    5102: 'CCH Folder more than one found',
    5103: 'Year Folder not found',
    5104: 'Year Folder more than one found',
    // 5105: 'ClientType Folder not found',
    5105: 'Return Type folder not found',
    5106: 'ClientType Folder more than one found',
    // 5107: 'Client Folder not found',
    5107: 'Some of the Clients you selected did not complete Step 1 of the Tax Return. Please review the list of Clients.',
    5108: 'Client Folder more than one found',
    5109: 'Tax document not found',
    5110: 'Document more than one found',
    5201: 'Step 3 has already been executed',
    5302: 'Golden template is already exists for this type of client ',
    5303: 'Incorrect Google Spreadsheet link!',
    //6XXX SOS
    6000: 'General SoS failure',
    6001: 'Unauthorized',
    6003: 'Access Denied',
    6004: 'Object Not Found',
    6011: 'Token Expired',
    6012:'Token Invalid',
    6034: 'Tenant not found',
    6100: 'Schedule Invalid',
    //9 Corporates and Individal
    9000: 'General Config failure',
    9001: 'Unauthorized',
    9003: 'Access Denied',
    9004: 'Object Not Found',
    9011: 'Token Expired',
    9012: 'Token Invalid',
    9034: 'Tenant not found',
    9134: 'Corporate not found',
    9135: 'Same website url for corporation is already exists',
    9137: 'Corporation`s name not found',
    9138: 'Corporation`s website url not found',
    9139: 'Corporation with the same name already exists',
    9140: 'Entity with the same CCH ID already exists in the system',
    9141: 'Corporation`s current CCH ID not found',
    9142: 'Corporation`s CCH EIN or SSN not found',
    9143: 'Corporation`s CCH office name not found',
    9144: 'Corporation`s CCH return group not found',
    9145: 'Corporation`s CCH client ID not found',
    9234: 'Individual not found',
    9235: 'Same email for individual is already exists',
    9237: 'Individual`s name incomplete',
    9238: 'Individual`s email not found',
    9239: 'Individual with the same name already exists',
    9240: 'Entity with the same CCH ID already exists in the system',
    9241: 'Individual`s current CCH ID not found',
    9242: 'Individual`s CCH EIN or SSN not found',
    9243: 'Individual`s CCH office name not found',
    9244:'Individual`s CCH return group not found',
    9245: 'Individual`s CCH client ID not found',
    9334: 'Contact Person not found',
    9335: 'Contact Person is already exists',
    9434: 'External tool not found',
    9435: 'External tools name not found',
    9436: 'External tools name exists',
    9437: 'External tools URL not found',
    9445: 'Component not found',
}   