<div class="title">Legend Update</div>
<div style=" max-height: 80%; overflow: auto; margin: 0 !important;" >
    <form
        class="mt-4 mb-4 justify-center flex items-center"
        [formGroup]="statusForm">   
    <div>
        <div class="flex-col groups" style="gap: 5px;">
           <div class="flex"  style="gap: 20px;">
            <div class="square" style="background-color: green;"></div>
            <div>
            <mat-form-field hideRequiredMarker="" class="md:w-full min-w-250 max-w-300">
                <input
                    maxlength="80"
                    matInput
                    name="good_status"
                    formControlName="good_status"
                >
                <mat-error
                    class="number-error"
                    *ngIf=" statusForm.get('good_status')?.touched && 
                        statusForm.get('good_status')?.hasError('required')">
                        Status is required
                </mat-error>
            </mat-form-field></div>
           </div>
           <div class="flex row"  style="gap: 20px;">
             <div class="square" style="background-color: yellow;"></div>
             <div>
             <mat-form-field hideRequiredMarker="" class="md:w-full min-w-250 max-w-300">
                 <input
                     maxlength="80"
                     matInput
                     name="unknown_status"
                     formControlName="unknown_status"
                 >
                 <mat-error
                     class="number-error"
                     *ngIf=" statusForm.get('unknown_status')?.touched && 
                         statusForm.get('unknown_status')?.hasError('required')">
                         Status is required
                 </mat-error>
             </mat-form-field>
             </div>
           </div>
           <div class="flex row"  style="gap: 20px;">
             <div class="square" style="background-color: red;"></div>
             <div>
             <mat-form-field hideRequiredMarker="" class="md:w-full min-w-250 max-w-300">
                 <input
                     maxlength="80"
                     matInput
                     name="bad_status"
                     formControlName="bad_status"
                 >
                 <mat-error
                     class="number-error"
                     *ngIf=" statusForm.get('bad_status')?.touched && 
                         statusForm.get('bad_status')?.hasError('required')">
                         Status is required
                 </mat-error>
             </mat-form-field>
             </div>
           </div>
           <div class="flex row"  style="gap: 20px;">
             <div class="square" style="background-color: white;"></div>
             <div>
             <mat-form-field hideRequiredMarker="" class="md:w-full min-w-250 max-w-300">
                 <input
                     maxlength="80"
                     matInput
                     name="not_status"
                     formControlName="not_status"
                 >
                 <mat-error
                     class="number-error"
                     *ngIf=" statusForm.get('not_status')?.touched && 
                         statusForm.get('not_status')?.hasError('required')">
                         Status is required
                 </mat-error>
             </mat-form-field>
             </div>
           </div>
        </div>
    </div>
   </form>
  </div>
   <div style=" display: flex; justify-content: space-between; margin: 0 10px;">
        <button
        class="fuse-mat-button "
        mat-flat-button
        [color]="'accent'"  
        [disabled]=""
        (click)="onSave()"
        type="button">
            <span>
            Save
            </span>
    </button> 
    &nbsp;&nbsp;&nbsp;
    <button
        class="fuse-mat-button "
        mat-flat-button
        [color]="'accent'"
        (click)="onCancel()"
        type="button">
            <span>
            Cancel
            </span>
    </button>
   </div>