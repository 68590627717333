import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsComponent } from './components/terms/terms.component';
import { JWTDecoder } from './jwt_decoder/jwt_decoder';
import { ComponentsGuard } from './core/auth/guards/components.guard';

export const appRoutes: Route[] = [
     {path: '', pathMatch : 'full', redirectTo: 'dashboard'},
     {path: 'policy', pathMatch : 'full', component: PolicyComponent,},
     {path: 'terms', pathMatch : 'full', component: TermsComponent,},
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'auth', loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule)}
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        children: [ 
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'sos', loadChildren: () => import('app/modules/sos/sos.module').then(m => m.SOSModule), canActivate: [ComponentsGuard], data: { permission: 'sos' }},
            {path: 'tax-returns', loadChildren: () => import('app/modules/return/return.module').then(m => m.ReturnModule), canActivate: [ComponentsGuard], data: { permission: 'tax_returns' }},
            // {path: 'tax-returns', loadChildren: () => import('app/modules/report/report.module').then(m => m.ReportModule)},
            {path: 'sales', loadChildren: () => import('app/modules/sales/sales.module').then(m => m.SalesModule), canActivate: [ComponentsGuard], data: { permission: 'tax_sales' }},
            {path: 'list', loadChildren: () => import('app/modules/list/list.module').then(m => m.ListModule)},
            // {path: 'return', loadChildren: () => import('app/modules/return/return.module').then(m => m.ReturnModule)},
            {path: 'report', loadChildren: () => import('app/modules/report/report.module').then(m => m.ReportModule), canActivate: [ComponentsGuard], data: { permission: 'qb' }},
            {path: 'configuration', loadChildren: () => import('app/modules/configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: [ComponentsGuard], data: { permission: 'system' }},
            // {path: 'access', loadChildren: () => import('app/modules/accessControl/manageCompanies.module').then(m => m.CompaniesRoutes)},
            {path: 'corporations', loadChildren: () => import('app/modules/corporations/corporations.module').then(m => m.CorporationsRoutes), canActivate: [ComponentsGuard], data: { permission: 'entities' }},
            {path: 'individuals', loadChildren: () => import('app/modules/individuals/individuals.module').then(m => m.IndividualsRoutes), canActivate: [ComponentsGuard], data: { permission: 'entities' }}
           
          ]
    },
    {path: '**', redirectTo: 'dashboard'}
];
