import { Injectable } from '@angular/core';
import { FuseAlertType } from '@fuse/components/alert';
import { Subject } from 'rxjs';
import { AlertInfo } from '../models/helper.model';
import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public showMessage = new Subject();

  constructor() { }

  // responseMessage(res: IResponse) {
  //   const json = JSON.parse(JSON.stringify(res));

  //   let alertInfo = new AlertInfo();
  //   alertInfo.message = json.messages[0];
  //   alertInfo.type = res.success ? 'success' : 'error';

  //   this.showMessage.next(alertInfo);
  // }

  message(message: string, type: FuseAlertType) {
    let alertInfo = <AlertInfo>{message, type};
    this.showMessage.next(alertInfo);
    
  }
}
