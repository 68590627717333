import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AuthService } from 'app/core/services/auth.service';
import { navigationData } from 'app/core/helpers/navigation-data';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Router } from '@angular/router';
import { NavigationMenuService } from 'app/core/services/navigationMenu.service';

@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    private menuUpdateSubscription: Subscription;
    @Input() breadcrumb: any[];
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userName = JSON.parse(localStorage.getItem('user'))?.nameFirst + ' ' + JSON.parse(localStorage.getItem('user'))?.nameLast;
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService,
        private _navMenuService: NavigationMenuService,
        public router: Router,        
        private _changeDetectorRef: ChangeDetectorRef,
        private _breadcrumbsService: NgDynamicBreadcrumbService
    ) {      
        this.menuUpdateSubscription = this._navMenuService.menuUpdate$.subscribe(async() => {
            this.navigation = await this._navMenuService.getNavMenuConfig();
            // this._changeDetectorRef.detectChanges();
        });
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    async ngOnInit(): Promise<void> {
        this.navigation = await this._navMenuService.getNavMenuConfig();
        // this.navigation = navigationData;
        if (this.breadcrumb.length) this._breadcrumbsService.updateBreadcrumb(this.breadcrumb);

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    signOut(): void {
        this._authService.signOut('success');
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.menuUpdateSubscription.unsubscribe();
    }

    toggleNavigation(name: string): void {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            navigation.toggle();
        }
    }

    get showFooter(): boolean {
        const urlsWithoutHeader = [ `auth` ];
        return !urlsWithoutHeader.some(el => this.router.url.includes(el));9
    }
}
